<template>
    <section id="section-gallery" data-aos="zoom-in" data-aos-duration="1000">
        <div class="row justify-content-md-center">
            <div class="col-12">
                <div class="container">
                    <div class="row ">
                        <div class="col-lg-12">
                            <h4 class="small center">Nézdd meg</h4>
                            <h4 class="title center">képeinket</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section filter-grid-portfolio" v-if="model.length > 0">
            <div class="container">
                <div class="row justify-content-center js-inview">
                    <div class="col-12">
                        <ul class="js-filters filters">
                            <li
                                v-for="(val, key) in option.getFilterData"
                                :class="[
                                    key === filterOption ? 'is-checked' : ''
                                ]"
                                v-bind:key="'filter-' + key"
                                @click="filter(key)"
                            >
                                {{ key }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="section grid-portfolio" v-if="model.length > 0">
            <div class="container-fluid">
                <div class="voffset-40"></div>
                <div class="row justify-content-center js-inview">
                    <div class="col-12">
                        <div class="grid-gallery-container">
                            <div id="gll" class="grid-gallery columns-3">
                                <isotope
                                    ref="cpt"
                                    id="root_isotope1"
                                    :item-selector="'element-item'"
                                    :list="model"
                                    :options="option"
                                    @filter="filterOption = arguments[0]"
                                    @sort="sortOption = arguments[0]"
                                    @layout="currentLayout = arguments[0]"
                                >
                                    <div
                                        v-for="(item, index) in model"
                                        v-bind:key="'isotop-' + index"
                                        :data-src="item.image"
                                        class="grid-item "
                                        v-bind:class="'cat-' + item.category_id"
                                    >
                                        <img :src="item.image" />
                                        <div class="grid-item__rollover pr">
                                            <a
                                                href="#"
                                                v-on:click.prevent="
                                                    openModal(item.image)
                                                "
                                                ><span class="ico-search"></span
                                            ></a>
                                        </div>
                                    </div>
                                </isotope>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="voffset-40"></div>
            </div>
        </div>
        <b-modal
            id="gallery-modal"
            title="BootstrapVue"
            ref="my-modal"
            size="xl"
            centered
            hide-footer
            hide-header
        >
            <img class="img-fluid" :src="modalImg" />
        </b-modal>
    </section>
    <!-- Gallery Section End-->
</template>

<script>
import isotope from "vueisotope";

export default {
    name: "Gallery",
    data() {
        return {
            modalImg: null,
            model: [],
            categories: [],
            currentLayout: "masonry",
            selected: null,
            sortOption: "original-order",
            filterOption: "Összes"
        };
    },
    components: { isotope },
    beforeMount() {
        this.getGallery();
    },
    watch: {
        $route: {
            handler: function() {
                this.getGallery();
            },
            deep: true
        }
    },
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        },
        option() {
            const self = this;
            let filterHelper = {
                Összes: function() {
                    return true;
                }
            };
            for (const it in self.categories) {
                filterHelper[self.categories[it].name] = function(el) {
                    return el.category_id === self.categories[it].id;
                };
            }

            return {
                itemSelector: ".element-item",
                getFilterData: filterHelper
            };
        }
    },
    methods: {
        openModal(image) {
            this.modalImg = image;
            this.$refs["my-modal"].show();
        },
        filter: function(key) {
            this.$refs.cpt.filter(key);
        },
        getGallery() {
            this.$http
                .get(`${this.url}/gallery/list`)
                .then(response => {
                    this.model = response.data.data;
                    this.categories = response.data.categories;
                })
                .finally(() => {
                    $(document).ready(function() {
                        $(".is-checked").click();
                        setTimeout(function() {
                            $(".is-checked").click();
                        }, 1500);
                    });
                });
        }
    }
};
</script>

<style>
#gallery-modal img {
    width: 100%;
}

#gallery-modal .modal-body {
    padding: 0;
}
</style>
